import {
   getAuth,
   GoogleAuthProvider,
   getRedirectResult,
   signInWithRedirect,
} from 'firebase/auth';
import { firebaseApp } from '../services/Firebase';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const GoogleLoginPage = () => {
   const [searchParams] = useSearchParams();
   const [loading, setLoading] = useState(true);
   const auth = getAuth(firebaseApp);
   const provider = new GoogleAuthProvider();

   useEffect(() => {
      const handleGoogleSignIn = async () => {
         try {
            const userCred = await getRedirectResult(auth);

            if (userCred && userCred.user) {
               console.log('✅ Google Sign-In result:', userCred);
               const firebaseIdToken = await userCred.user.getIdToken();
               console.log('🔑 Firebase ID token:', firebaseIdToken);

               const redirectUri = searchParams.get('redirect_uri') ?? '';
               sessionStorage.removeItem('redirecting'); // Clear flag after successful login

               // if (redirectUri) {
               //    window.location.href = `${redirectUri}?success=true&token=${firebaseIdToken}`;
               // }
               return;
            }

            // Prevent infinite redirect loop using sessionStorage
            if (!sessionStorage.getItem('redirecting')) {
               console.log('🔄 No user detected, redirecting to Google...');
               sessionStorage.setItem('redirecting', 'true'); // Mark redirect started
               await signInWithRedirect(auth, provider);
            }
         } catch (error) {
            console.error('❌ Google Sign-In failed:', error);
            sessionStorage.removeItem('redirecting'); // Clear flag on error
         } finally {
            setLoading(false);
         }
      };

      handleGoogleSignIn();
   }, []);

   return (
      <div>
         {loading
            ? 'Redirecting to Google Sign-In...'
            : 'Authentication complete!'}
      </div>
   );
};

export default GoogleLoginPage;
